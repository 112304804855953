<template>
  <div class="home">
    <section class="main">
      <div class="main-section">

        <div class="main-img">
          <img src="@/assets/images/main-bg.png" alt="ATK Transportation Van" />
        </div>

        <div class="main-section-info">
          <h1 class="main-section-text">Ride Safe with ATK</h1>
          <span class="main-section-description">We go the extra mile so that you can focus on what matters.</span>
          <button @click="goToInfo" type="button" class="btn contact-btn">Contact</button>
        </div>
      </div>
    </section>
    <section class="info-section" id="info-section">
      <div class="info">
        <div class="inner-info">
          <span class="inner-span">
            <h2 class="mt-2">On The Go</h2>
            <span>
              ATK Transportation, LLC offers non-emergency medical transportation ensuring professional ambulatory,
              wheelchair, stretcher, and leisure services. For over seven years, we have worked to build a favorable
              reputation within the Fredericksburg, Virginia community.
            </span>
          </span>
        </div>
      </div>
      <div class="info">
        <div class="inner-info">
          <span class="inner-span">
            <h2 class="mt-2">Here For You</h2>
            <span>Your safety and satisfaction are our main priorities. Unwind and ride with comfort and care at
              affordable rates with our qualified transport facilitators. Give us a call, or text, for a faster response,
              to schedule a ride.</span>
            <span class="inner-info-contact">
              <span class="contact-method">
                <a href="tel:+15408988438" class="contact-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="darkred" class="bi bi-telephone"
                    viewBox="0 0 16 16">
                    <path
                      d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                  </svg>
                </a>
                <a href="tel:+15408988438" style="margin-left: 5px; text-decoration: none;"> +1 (540) 898-8438 </a>
              </span>
              <span class="contact-method">
                <a href="sms:+15404551138" class="contact-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="darkred" class="bi bi-chat-dots"
                    viewBox="0 0 16 16">
                    <path
                      d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                    <path
                      d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z" />
                  </svg>
                </a>
                <a href="sms:+15404551138" style="margin-left: 5px; text-decoration: none;"> +1 (540) 455-1138 </a>
              </span>
            </span>
          </span>
        </div>
      </div>
    </section>
    <section class="van-pictures">
      <div class="van-pictures-section">
        <img class="van-pic" src="@/assets/images/vanpic1.png" alt="Van Pictures 1" />
        <img class="van-pic" src="@/assets/images/vanpic2.png" alt="Van Pictures 2" />
        <img class="van-pic" src="@/assets/images/vanpic3.png" alt="Van Pictures 3" />
      </div>
    </section>
    <section class="counties-section">
      <section class="counties-inner">
        <div class="counties-seals">
          <div class="county">
            <img class="county-img" src="@/assets/images/county1.png" />
          </div>
          <div class="county">
            <img class="county-img" src="@/assets/images/county2.png" />
          </div>
          <div class="county">
            <img class="county-img" src="@/assets/images/county3.png" />
          </div>
          <div class="county">
            <img class="county-img" src="@/assets/images/county4.png" />
          </div>
        </div>
        <div class="counties-text">
          <span class="text-header">Serving Fredericksburg, Virginia and its surrounding counties.</span>
          <div class="counties-names">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" class="bi bi-geo-alt-fill"
                viewBox="0 0 16 16">
                <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
              </svg>Caroline
            </span>
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" class="bi bi-geo-alt-fill"
                viewBox="0 0 16 16">
                <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
              </svg>Culpeper
            </span>
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" class="bi bi-geo-alt-fill"
                viewBox="0 0 16 16">
                <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
              </svg>Fauquier
            </span>
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" class="bi bi-geo-alt-fill"
                viewBox="0 0 16 16">
                <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
              </svg>King George
            </span>
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" class="bi bi-geo-alt-fill"
                viewBox="0 0 16 16">
                <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
              </svg>Louisa
            </span>
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" class="bi bi-geo-alt-fill"
                viewBox="0 0 16 16">
                <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
              </svg>Madison
            </span>
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" class="bi bi-geo-alt-fill"
                viewBox="0 0 16 16">
                <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
              </svg>Orange
            </span>
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" class="bi bi-geo-alt-fill"
                viewBox="0 0 16 16">
                <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
              </svg>Spotsylvania
            </span>
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" class="bi bi-geo-alt-fill"
                viewBox="0 0 16 16">
                <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
              </svg>Stafford
            </span>
          </div>
        </div>
        <div class="counties-seals">
          <div class="county">
            <img class="county-img" src="@/assets/images/county5.png" />
          </div>
          <div class="county">
            <img class="county-img" src="@/assets/images/county6.png" />
          </div>
          <div class="county">
            <img class="county-img" src="@/assets/images/county7.png" />
          </div>
          <div class="county">
            <img class="county-img" src="@/assets/images/county8.png" />
          </div>
          <div class="county">
            <img class="county-img" src="@/assets/images/county9.png" />
          </div>
        </div>
      </section>
    </section>
  </div>
</template>

<script>

export default {
  name: 'Home',
  setup() {
    const goToInfo = () => {
      document.getElementById('info-section').scrollIntoView();
    }

    return { goToInfo }
  }
}
</script>

<style scoped>
.main {
  padding-top: 5rem;
  background-color: rgba(209, 209, 209, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
  overflow-x: hidden;
  height: 80vh;
  width: 100%;
}

.main-section {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.main-section-info {
  display: flex;
  flex-flow: row wrap;
  align-content: center;
  justify-content: center;
  position: relative;
  z-index: 3;
  background-color: rgba(209, 209, 209, 0.8);
  width: 100%;
  height: 100%;
}

.main-section .main-img {
  position: absolute;
  top: 0;
  left: -10px;
  height: 100%;
  z-index: 2;
}

.main-img img {
  height: 100%;
  opacity: 0.8;
}

.main-section-text {
  color: rgb(44, 0, 0);
  text-shadow: 1px 1px #dd0000;
  font-size: calc(26px + 2vw);
  width: 100%;
  font-weight: bold;
}

.main-section-description {
  width: 100%;
  font-weight: bold;
  padding: 0 1rem;
  font-size: 18px;
}

.main-section-card {
  width: 50%;
  height: 50%;
}

.info-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;
  background: rgba(209, 209, 209, 0.2);
  scroll-margin-top: 4.5rem;
}

.info {
  margin: 10px 18px;
  max-width: 25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(209, 209, 209, 0.5);
  border: 1px solid lightgrey;
  border-radius: 10px;
  padding: 15px 10px;
}

.info-header-text {
  width: 100%;
  font-size: 36px;
  color: rgb(46, 0, 0);
  margin: 3rem;
  margin-bottom: 4rem;
}

.contact-btn {
  border-color: darkred;
  color: white;
  background: darkred;
  margin-top: 25px;
}

.contact-method {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row wrap;
  width: 100%;
}

.contact-method a {
  color: darkred;
}

.contact-btn:hover,
.contact-btn:focus {
  outline: 1px solid darkred;
}

.inner-info {
  width: 100%;
  padding: 15px;
  padding-top: 10px;
  text-align: left;
  color: darkslategrey;
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  overflow-y: scroll;
  font-weight: bold;
  display: flex;
  flex-flow: column nowrap;
}

.inner-info .mt-2 {
  filter: drop-shadow(0 0 1px darkred);
}

.inner-info::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

.inner-info .mt-2 {
  height: 20%;
}

.inner-info span {
  font-size: 14px;
}

.info-icon-div {
  width: calc(4rem + 1vw);
  display: inline-block;
  filter: drop-shadow(0 0 2px darkred);
}

.info-icon-div img {
  width: 100%;
}

.inner-info .inner-span {
  display: flex;
  flex-flow: column nowrap;
}

.inner-info-contact {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  flex-flow: column nowrap;
}

.contact-icon {
  margin: 10px;
  text-decoration: none;
}

.van-pictures {
  display: flex;
  align-items: center;
  background: darkred;
  width: 100%;
  height: 45vh;
  overflow-x: auto;
  padding: 10px 0;
}

.van-pictures-section {
  height: 100%;
  background-color: darkred;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin: 0 3rem;
}

.van-pic {
  height: 95%;
  margin-right: 20px;
  border-radius: 8px;
  filter: drop-shadow(0 0 2px lightgrey);
}

.counties-section {
  overflow: hidden;
  background: rgba(216, 2, 2, 0.2);
}

.counties-inner {
  width: 100%;
  height: 100%;
  background: rgb(230, 230, 230, 0.5);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.counties-seals {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
}

.county {
  width: 15vw;
  max-width: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.county-img {
  width: 100%;
}

.counties-text {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 20px;
  font-style: italic;
  flex-flow: column nowrap;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.counties-text .text-header {
  width: 70%;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 10px;
}

.counties-names {
  display: flex;
  flex-flow: row wrap;
  max-width: 20rem;
  margin-bottom: 15px;
  justify-content: center;
  align-items: center;
  background: rgb(255, 255, 255, 0.5);
  padding: 8px;
  border-radius: 8px;
}

.counties-names span {
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 8px;
  min-width: 15%;
}

.counties-names svg {
  margin-right: 2px;
}

@media only screen and (max-width: 300px) {
  .main-section-info {
    padding: 0 10px;
  }

  .inner-info {
    padding: 5px;
    padding-top: 8px;
  }

  .counties-text .text-header {
    font-size: 16px;
  }

  .counties-names {
    max-width: 95%;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 800px) {
  .main {
    height: 90vh;
  }
  .van-pictures {
    height: 65vh;
  }
  .main-section-text {
    font-size: calc(35px + 2vw);
  }
}

@media only screen and (min-width: 1010px) {
  .main {
    height: 100vh;
  }
  .main-section-text {
    font-size: calc(38px + 2vw);
  }

  .counties-text .text-header {
    font-size: 24px;
  }

  .counties-names {
    max-width: 34rem;
  }

  .counties-names span {
    font-size: 14px;
  }
}
</style>
