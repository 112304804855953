<template>
  <section>
    <nav class="nav fixed-top">
      <div class="inner-nav">
        <img src="@/assets/images/atk-logo.png" alt="ATK Logo" class="atk-logo" />
        <div class="navbar-text">
          <span>Non-Emergency Medical Transportation</span>
        </div>
      </div>
      <div class="location-nav">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="white" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
          <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
        </svg>
        <span style="margin-left: 8px;">Fredericksburg, Virginia</span>
      </div>
    </nav>
    <router-view />
    <Footer />
  </section>
</template>

<script>
import Footer from "./Footer"

export default {
  components: {
    Footer
  }
}
</script>

<style>
.nav {
  background-color: rgb(59, 2, 2);
}

.inner-nav {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.atk-logo {
  width: calc(75px + 1vw);
  padding: 2px;
  margin: 5px;
  margin-left: 8px;
}

.navbar-text {
  max-width: 70%;
  padding: 2px;
  background: white;
  min-height: 2rem;
  padding-left: 10px;
  padding-right: 5px;
  display: flex;
  align-items: center;
  border: 1px solid white;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  margin: 2px;
  margin-left: auto;
}

.navbar-text span {
  color: darkred;
  font-weight: bold;
}

.location-nav {
  background-color: rgb(44, 0, 0);
  color: white;
  font-weight: bold;
  width: 100%;
  padding: 5px;
  padding-right: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>