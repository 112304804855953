<template>
  <footer class="footer" id="footer">
    <img src="@/assets/images/atk-logo.png" class="atk-footer-logo" alt="ATK Logo" />
    <span class="small-text">
      Anthony T. Kay (Tony), Owner.
    </span>
    <a href="tel:+15408988438" class="w-100 footer-contact">
      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="lightgray" class="bi bi-telephone-fill"
        viewBox="0 0 16 16">
        <path fill-rule="evenodd"
          d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
      </svg>
      <span class="small-text contact-text">+1 (540) 898-8438</span>
    </a>
    <a href="sms:+15404551138" class="w-100 footer-contact">
      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="lightgray" class="bi bi-chat-dots-fill"
        viewBox="0 0 16 16">
        <path
          d="M16 8c0 3.866-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.584.296-1.925.864-4.181 1.234-.2.032-.352-.176-.273-.362.354-.836.674-1.95.77-2.966C.744 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7zM5 8a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm4 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
      </svg>
      <span class="small-text contact-text">+1 (540) 455-1138</span>
    </a>
    <a href="mailto:atktransportation@gmail.com" class="w-100 footer-contact">
      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="lightgray" class="bi bi-envelope-fill"
        viewBox="0 0 16 16">
        <path
          d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z" />
      </svg>
      <span class="small-text contact-text">atktransportation@gmail.com</span>
    </a>
    <a href="https://www.facebook.com/ATKTransportationLLC/" target="_blank" rel="noopener noreferrer"
      class="w-100 footer-contact">
      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="lightgray" class="bi bi-facebook"
        viewBox="0 0 16 16">
        <path
          d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
      </svg>
      <span class="small-text contact-text">Connect With Us</span>
    </a>
    <span class="small-text">
      Copyright &copy; {{ currentYear }} ATK Transportation, LLC. All rights reserved.
    </span>
    <span class="small-text">Powered by The NixTech Company</span>
  </footer>
</template>

<script>
import { ref } from "vue"

export default {
  setup() {
    const currentYear = ref('')

    let date = new Date();
    currentYear.value = date.getFullYear();

    return { currentYear }
  }
}
</script>
<style>
.footer {
  height: auto;
  background: rgb(102, 102, 102);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  padding-left: 30px;
  text-align: left;
}

.footer-contact {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 7px;
}

.small-text {
  font-size: 14px;
  color: lightgray;
  margin-top: 3px;
  width: 100%;
}

.contact-text {
  margin-left: 9px;
}

.atk-footer-logo {
  width: 12rem;
  margin-bottom: 12px;
}

.footer-contact svg:hover {
  text-decoration: none;
  color: rgb(58, 58, 58);
  fill: rgb(58, 58, 58);
}

.footer a:hover,
.footer a:focus {
  text-decoration: none;
}

@media only screen and (max-width: 300px) {
  .footer {
    padding-left: 10px;
  }

  .atk-footer-logo {
    width: 8rem;
  }
}</style>