<template>
  <Nav />
</template>

<script>
import Nav from "@/components/Nav"

export default {
  components: {
    Nav
  }
}
</script>
<style>
#app {
  font-family: "Century Gothic", CenturyGothic, AppleGothic, Avenir, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border-radius: 4px;
}

/* Track */
*::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
